.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color:#188bf7
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  }