.ant-select-selection-selected-value{
  float : right;
  max-width : 100%;
  overflow : hidden;
  white-space : nowrap;
  text-overflow : ellipsis;
}
.ant-select-selection__placeholder, .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}