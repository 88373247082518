.ant-card-head-title{
    font-size:16px;
    font-weight: '700';
}
.VictoryContainer tspan{
    font-size: 12px !important;
    font-weight: 1000 !important
}
.VictoryContainer{
    border: none !important;
    position: inherit !important;

}
.chartClass{
    border: 2px solid#bfbfbf !important
}
.chartClass .ant-card-head {
background-color: #fff !important;
}
.chartClass .ant-card-head h1 {
align-self: center !important;
color: #fc5c7d !important
}
.year-picker{
    line-height: 2.3 !important;
    width:100% !important;
}
.selected-year {
    background: #FC5C7D !important;

}
a {
    /* color: rgba(0, 0, 0, 0.65) !important */
}