
input#file {
    border: none !important;
  }
  input#file {
    border: none !important;
    margin-right:0px !important;
    background-color: transparent;
    margin-left: -8px;
    color: transparent !important;

}  