.ant-collapse-header{
    background-color: #42AAFF;
    }
    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color:white;
        font-weight: bold
    }

    
input#files {
    border: none !important;
  }
  .ant-modal-wrap .ant-modal .ant-modal-body .ant-form .ant-form-vertical .ant-form-item-control-wrapper .ant-form-item-control .ant-form-item-children #files {
    border: none !important;
  }
 input#files {
    border: none !important;
    float: left;
    margin-right: 40px !important;
    background-color: transparent;
    margin-left: -8px;
    width: 95px !important;
    color: transparent !important;

}   