.rbc-month-row {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 100px;
    flex: 100px;
    overflow: hidden;
    height: 100%;
    }

    g path {
        stroke: #fc5c7d!important;
        fill: #fc5c7d!important;
        padding: 20 !important;
        margin-left: 300px !important;
    }


     .ant-table>.dashboardComponent>{
    line-height: 0 !important
}

.VictoryContainer {
    background: #fff;
    margin: 20px 0px 0 -20px !important;
    float: left;
    border: 1px solid#e5e5e5e5;
}
.VictoryContainer{
    width:100% !important;
    height:280px !important
}

.VictoryContainer>svg {
    width:100% !important;
    height:280px !important;
    font-weight: bold
}
span.ant-table-column-title{
    padding: 0px 0 3px 0 !important;

}

.rbc-calendar {
    margin: 20px 20px;
    border: 1px solid#e5e5e5e5;
    background: #fff;
    padding: 0px 40px 20px;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    background: -webkit-linear-gradient(top, #7579ff, #b224ef);
    margin: 20px 0 0px 0;
}

.chart-list {
    width: 100%;
    float: left;
}
.rbc-event-content[data-title]:hover:after {
    content: attr(data-title);
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    background: #000000;
    color: #fff;
    z-index: 99 !important;
    top: 70%;
    opacity:1 !important
}
.rbc-event.rbc-event-allday {
    opacity: 1 !important;
}

