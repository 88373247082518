.loginFormCSS {
    margin-top: 90px;
    /* background: -webkit-linear-gradient(top, #7579ff, #b224ef); */
    color: #fff;
    text-align: center;
    border: none !important;
}
.loginFormCSS2{
    margin-top: 15vh;
    background-color: rgba(255, 255, 255, 0.28);
    color: #fff;
    text-align: center;
    border: none !important;
}

.loginFormCSS label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    float: left;
    color: #fff;
    margin: 0 0 10px 0;
}

.loginFormCSS h1 {
    color: #fff;
    margin: 10px 0 40px 40px;
}

.loginFormCSS a{
    color: #fff;
    /* margin: 40px 0 0px 0; */
    /* float: left; */
    /* text-align: center; */
}

.loginFormCSS .ant-btn-primary {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}

.loginFormCSS .ant-checkbox-wrapper {
    float: left;
    color: #fff;
    margin: 0 0 10px 0;
}

.login-back{
    /*  background-image: url('/cropped-marquee-background-2.jpg'); */
    background-image: url('/pngtree-hd-dream-aperture-background-material-image_280277.jpg');
    /* background-image: url('/pngtree-hd-dream-aperture-background-material-image_280277_2.jpg'); */
    /* background-image: url('/blue_purple_red_1386191104.jpg'); */
    width: 100%;
    margin: 0 !important;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    /* display: flex;
    flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}


.login-back::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background-color: rgba(255,255,255,0.9); */
    opacity: 0.8;
}
