div#clientTableHeader p {
    text-align: center;
    font-size: 23px !important;
    font-weight: 400 !important;
    color: #000;
    margin: -6px 29px 50px -9px;
}

thead.ant-table-thead {
    border-top: 3px solid#3C8DBC;
    margin: 0px 0 0 0;
    padding: 0px 0 0 0;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: -webkit-linear-gradient(top, #7579ff, #b224ef) !important;
}

.ant-layout-header {
    height: 74px !important;
    padding: 0 50px;
    line-height: 71px !important;
    background: -webkit-linear-gradient(top, #7579ff, #b224ef);
}

.ant-layout-header .anticon.anticon-menu-unfold.trigger {
    margin-top: 22px !important;
}

.ant-layout-header .anticon.anticon-menu-fold.trigger {
    margin-top: 22px !important;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: #E9E9E9;
    color: #000;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 26px 6px;
}

.ant-table-tbody>tr>td {
    border-bottom: 1px solid #ccc7c7;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
}


.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color: #3C8DBC;
    font-weight: 500;
}

span.ant-table-column-title {
    font-weight: 700;
    padding: 0px 0 30px 0;
    font-size: 14px;
    line-height: 33px;
}

.ant-btn-danger {
    color: #3C8DBC;
    background-color: #f5f5f5;
    border-color: #3C8DBC;
}

.ant-collapse-header {
    background-color: #3C8DBC;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: #3c8dbc;
}


.ant-btn-primary {
    color: #fff;
    background-color: #fc5c7d !important;
    border-color: #fff;
}



.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #001529;
    box-shadow: 0 2px 0px rgba(0, 0, 0, 0.45) inset;
}

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected {
    background-color: #1e282c !important;
    color: #fff;

}

.ant-menu-submenu-open,
.ant-menu-submenu-selected {
    background-color: #1e282c !important;
    color: #fff;

}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: #1e282c;
}

.ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: #1e282c;
}

.ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected {
    background-color: #1e282c;
    color: #fff;

}

.ant-menu-submenu-title {
    background: #001529;
    color: #b8c7ce;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529 !important;
}


.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #1e282c;
    color: #fff;
    border-left: 5px solid#3c8dbc
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #2c3b41;
}


.top-header {
    background: -webkit-linear-gradient(top, #7579ff, #b224ef) !important;
    padding: 20px 40px 10px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
}

.top-header h1 {
    color: #fff !important;
}

.top-header h1 {
    color: #fff !important;
    font-weight: bold;
    text-transform: capitalize;
    text-align: right;
    font-size: 18px;
    padding: 20px 0px;
}

.top-header .save {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    border: none;
    font-size: 23px;
    float: left;
    margin: 40px 0px 0 -17px;
}

.top-header .delete {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    border: none;
    font-size: 23px;
    float: left;
    margin: 40px 0px 0 -15px;
}

.top-header .delete:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
    height: 35px;
    /* line-height: 40px; */
    /* padding: 0px 20px; */
}

.top-header .save:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
    height: 35px;
    /* line-height: 40px; */
    /* padding: 0px 20px; */
}

.ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    margin: 10px 0px 30px 7px;
}

.rbc-toolbar button {
    color: #fff;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 2px solid #fff;
    border-radius: 2px;
    line-height: normal;
    white-space: nowrap;
    padding: 5px 10px;
    margin: 20px 20px 20px 40px;
    font-size: 12px;
}

span.rbc-toolbar-label {
    color: #fff;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
    min-height: 0;
    /* color: #fc5c7d!important; */
    border-bottom: 1px solid #e5e5e5;
    color: #fff;
    background: #fc5c7d !important;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0px 10px;
    text-align: left;
    font-weight: bold;
}

.rbc-month-view {
    position: relative;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 464px;
    margin: 0 auto;
}

.top-header button.ant-btn.ant-btn-primary {
    /* float: right; */
    margin: 15px 0 0 42px;
    /* float: right; */
}

.top-header button.ant-btn.ant-btn-danger {
    float: right;
    margin: 17px 20px;
}

.top-header button.ant-btn.ant-btn-primary {
    /* float: right; */
    margin: 4px 0;
    float: right;
}

.top-header button.ant-btn.ant-btn-danger:first-child {
    float: right !important;
    margin: 17px 0;
}

input.ant-calendar-picker-input.ant-input {
    font-size: 10px;
}

div#clientTableHeader button {
    float: left;
    margin: 0 0 0 109px;
}

div#clientTableHeader p {
    text-align: center;
    font-size: 23px !important;
    font-weight: 400 !important;
    color: #000;
    margin: -6px 10px 50px 0px;
    float: right;
}

.ant-table table {
    width: 100%;
    text-align: right;
    border-radius: 4px 4px 0 0;
    border-collapse: collapse;
}

.top-header h2 {
    color: #fff !important;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
    font-size: 15px;
    padding: 20px 0px;
}

.ant-row.top-header .ant-form-item {
    margin-bottom: 0 !important;
}

.ant-form-item-label>label {
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
}

.ant-card-bordered {
    border: 3px solid#3C8DBC;
}

.ant-row.custom-box {
    margin: 10px 0px 0px 0px;
}

/* 
.VictoryContainer {
    margin: 0 auto !important;
    width: 80%
} */

.chart-table {
    margin: 0px 0 0 -20px;
    background: #fff;
    padding: 0px 0;
    width: 100%;
    float: left;
}

.chart-table thead.ant-table-thead {
    border-top: none;
    margin: 0px 0 0 0;
    padding: 0px 0 0 0;
    background: -webkit-linear-gradient(top, #7579ff, #b224ef) !important;
    border: none !important;
}

.chart-table .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0 0px;
    border-radius: 0px !important;
}

.selectFormCSS a {
    color: #fff;
    margin: -15px 0 40px 0;
    z-index: 999999;
    float: left;
}

.selectFormCSS h1 {
    color: #fff;
    margin: 0px 0 20px 0;
    float: right;
}
button.ant-btn.confirmBtn.ant-btn-primary {
    margin:4px -26px 0 0 !important
}
